export const USER_RECOMMENDATIONS_FETCH = 'frontend/user-recommendations/USER_RECOMMENDATIONS_FETCH';

export const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_RECOMMENDATIONS_FETCH:
      return {
        ...state,
        recommendations: action.payload,
      };
    default:
      return state;
  }
}
